import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/user/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${idx}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    /* 인플루언서리스트 */

    // 인플루언서 리스트 받기
    fetchInfluencerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/list/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 디테일 받기
    fetchInf(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/detail/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 업데이트
    updateInf(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/update/`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 포인트 관리
    pointInf(ctx, pointForm) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/point/`, pointForm)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 경고 관리
    cautionInf(ctx, cautionForm) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/caution/`, cautionForm)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 메모 작성
    memoInf(ctx, memoForm) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/memo/`, memoForm)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchTimelines(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/timelines/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getInfCautions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/cautions/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 회원탈퇴
    deleteInf(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/delete/`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 재가입
    revertInf(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/revert/`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 생성
    createInf(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/create/`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 sns추가
    addSns(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/add-sns/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 sns추가
    upgradeSns(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/upgrade-sns/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 앱 푸시
    appPushInf(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          // .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/app-push/`, data)
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/app-push/post/`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 앱 푸시
    infAppPush(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/send-messages/`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    infUpdateCautionState(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/update-caution-state/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    infUpdateCautionExpiredAt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/update-caution-expired_at/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    /* 광고주 */

    // 광고주 리스트 받기
    fetchAdvertiserList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/list/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 디테일 받기
    fetchAdv(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/detail/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 타임라인 받기
    fetchAdvTimeline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/timelines/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 업데이트
    updateAdv(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update/`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 업데이트
    cautionAdv(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/caution/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 광고주 업데이트
    blackAdv(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/ban/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getAdvCautions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/cautions/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 포인트 관리
    pointAdv(ctx, pointForm) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/point/`, pointForm)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    advUpdateCautionState(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update-caution-state/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    advUpdateCautionExpiredAt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/update-caution-expired_at/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    /* 어드민 */

    // 어드민 리스트 받기
    fetchAdminList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/admin/list/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
