import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function adminViewModel() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'idx' },
    { key: 'name' },
    { key: 'phone' },
    { key: 'team' },
    { key: 'permission' },
    { key: 'state' },
    { key: 'actions' },
  ]
  const perPage = ref(20)
  const users = ref([])
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = ref(20)
  const searchQuery = ref('')
  const sortBy = ref('idx')
  const isSortDirDesc = ref(true)
  const selectedUsers = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const fetchAdminList = (page, size, callback) => {
    store
      .dispatch('app-user/fetchAdminList', {
        page,
        size,
      })
      .then(response => {
        const { page_info, data } = response.data
        users.value = data
        totalUsers.value = page_info.total_count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // watch([perPage, searchQuery], () => {
  //   currentPage.value = 1
  //   fetchInfluencerList(currentPage.value, perPageOptions.value)
  // })

  watch([currentPage, perPageOptions], () => {
    fetchAdminList(currentPage.value, perPageOptions.value)
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {

    fetchAdminList,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    users,
    selectedUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,

  }
}
